import { useEffect, useState } from "react";
import {
  Box,
  Text,
  Center,
  Flex,
  Divider,
  Button,
  Link,
  useColorModeValue,
  Image,
  Heading,
  useColorMode,
} from "@chakra-ui/react";
import T from "../../../../Core/Translations";
import { EmailIcon } from "@chakra-ui/icons";
import config from "../../../../Config";
import { CLR_PANEL_BG, CLR_PANEL_BG_DARK } from "../../../../../Lib/Theme/Light/colors";
import useUser from "../../../../Core/Hooks/useUser";
import PlatformUsageBox from "../../../SessionJoined/Components/PlatformUsageBox/Web";
import ConsentScreen from "../../../ConsentScreen/Web";

const sendToParent = (command: string, data?: any) => {
  window.parent?.postMessage({
    boostevents: "boostevents",
    command,
    ...data,
  }, '*');
};

function PublicDashboard() {
  
  const [consentScreen, setConsentScreen] = useState(false);
  
  if (consentScreen) {
    return (
      <ConsentScreen />
    );
  }
  
  return (
    <Center
      display="flex"
      flexDirection="column"
      justifyContent="flex-start"
      alignItems="center"
      mt={[0, 0, 20]}
    >
      <Flex justify="center" gap="12px" mt="12px" mb="24px">
        <Flex
          maxW={["full", "full", "1000px"]}
          h={["calc(100vh)", "calc(100vh)", "full"]}
          bgColor="white"
          flexDirection="column"
          padding={[8, 8, 10]}
          overflow="auto"
        >
          <Image src={'/assets/images/boostlingo-events.jpg'} alt="Dashboard" />
          <Heading textAlign="center" fontSize="20px" m="30px 0">{T.get('Captions and AI Translations for your meetings')}</Heading>
          <Flex justify="center" gap="12px" mt="12px" mb="24px">
            <Button onClick={() => setConsentScreen(true)} variant="beSecondary" fontSize="30px" p="30px">
              {T.get('Get started!')}
            </Button>
          </Flex>
        </Flex>
        <Box padding={[8, 8, 10]}>
          <Image src={'/assets/images/ai-pro-preview.jpg'} alt="Dashboard" maxW="700px" />
        </Box>
      </Flex>
    </Center>
  );
}

function UserDashboard(props: any) {
  const { user } = props;
  
  const startZoomMeeting = () => sendToParent('startMeeting');
  const learnMore = () => sendToParent('openUrl', { url: "https://support.ai-pro.boostlingo.com/hc/en-us" });
  const aiProDashboard = () => sendToParent('openUrl', { url: config.access?.url });
  
  const onUpgrade = () => sendToParent('openUrl', { url: config.access?.url });;
  
  return (
    <Center
      display="flex"
      flexDirection="column"
      justifyContent="flex-start"
      alignItems="center"
      mt={[0, 0, 20]}
    >
      <Flex
        maxW={["full", "full", "1000px"]}
        h={["calc(100vh)", "calc(100vh)", "full"]}
        bgColor="white"
        flexDirection="column"
        padding={[8, 8, 10]}
        overflow="auto"
      >
        <Image src={'/assets/images/aipro-logo.jpg'} alt="Dashboard" maxW="400px" />
        <Center><Heading textAlign="center" fontSize="20px">{T.get('Welcome to Boostlingo AI Pro - Captions and AI Translations for your meetings')}</Heading></Center>
        <Box p="40px 0">
          <PlatformUsageBox dashboardStyle={true} onUpgrade={onUpgrade} />
        </Box>
        <Flex justify="center" gap="12px" mt="12px" mb="24px">
          <Box>
            <Image src={'/assets/images/aipro-logo2.jpg'} alt="Dashboard" maxW="100px" />
          </Box>
          <Center>
            <Flex justify="center" gap="12px">
              <Button onClick={startZoomMeeting} variant="beSecondary">{T.get('Start meeting in Zoom')}</Button>
              <Button onClick={learnMore} variant="beSecondary">{T.get('Learn more')}</Button>
              <Button onClick={aiProDashboard} variant="beSecondary">{T.get('Log into AI Pro Dashboard')}</Button>
            </Flex>
          </Center>
        </Flex>
      </Flex>
    </Center>
  );
}

export default function ZoomDashboardScreen() {
  
  const { user, getActiveUser } = useUser();
  
  const { setColorMode } = useColorMode();
  
  useEffect(() => {
    setColorMode('light'); // the dashboard is always in light mode in Zoom
    
    function onMessage(message: any) {
      switch (message.data.command) {
        case "refresh": // message is sent from Zoom wrapper parent IFRAME when user accepts consent screen and authorizes successfully (i.e. already logged in and a user account has been created in our database)
          //window.location.reload();
          getActiveUser(); // this will retrieve currently logged in user and will show Dashboard instead of Welcome screen if user already has logged in
          break;
      }
    }
    window.addEventListener('message', onMessage);
    return () => window.removeEventListener('message', onMessage);
  }, []);
  
  if (!user?.id) { // user is an empty object if it's not loaded
    return <PublicDashboard />; // user not yet logged-in in our app
  }
  return <UserDashboard user={user} />; // logged-in user
}
