import { Box, Progress, Text, Button, Flex } from "@chakra-ui/react";
import { useUserSubscription } from "../../../../../Core/Hooks/useDashboard";
import T from "../../../../../Core/Translations";
import { CLR_SECONDARY_BG, CLR_MUTED_GREEN } from "../../../../../../Lib/Theme/Light/colors";

const PlatformUsageBox = ({
  session, sessionLiveMinutesUsed, dashboardStyle, onUpgrade
}: {
  session?: any;
  sessionLiveMinutesUsed?: number;
  dashboardStyle?: boolean;
  onUpgrade?: () => void;
}) => {
  const { userSubscription } = useUserSubscription();

  const sessionHasEnded = session?.session?.session?.status === "stopped";

  const minutesUsed = sessionLiveMinutesUsed ? sessionLiveMinutesUsed : userSubscription?.minutesUsed || 0;

  const plan = session?.session?.session?.plan;
  const sessionIsFreeTrial = plan ? plan?.name === "freeTrial" : userSubscription?.freeTrial;
  const freeMinutes = userSubscription?.trial?.freeMinutes;

  if (!sessionIsFreeTrial) {
    return null;
  }
  
  let addAttrs: any = dashboardStyle ? {
    borderRadius: "10px",
    bgGradient: `linear(to-b, ${CLR_SECONDARY_BG}, ${CLR_MUTED_GREEN})`,
  } : {
    borderBottomRadius: "4px",
  };

  return (
    <Box
      bg="mutedBlue"
      w="full"
      p="10px"
      pl="23px"
      pr="23px"
      {...addAttrs}
    >
      <Flex>
        <Text variant="beTextDescriptionFixed" w="full">{T.get('You are on the free trial')}</Text>
        {!!onUpgrade && <Button size="sm" mb="10px" onClick={onUpgrade}>Upgrade</Button>}
      </Flex>
      <Progress
        aria-label={T.get("Usage minutes used")}
        borderRadius="4px"
        max={userSubscription?.trial?.freeMinutes}
        bg="white"
        value={minutesUsed}
      />
      <Text textAlign="right" variant="beTextDescriptionFixed">
        {minutesUsed} of {freeMinutes} minutes used
      </Text>
    </Box>
  );
};

export default PlatformUsageBox;
